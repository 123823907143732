import kiosk from '../../assets/images/kiosk.svg'
import magnify from '../../assets/images/magnify.svg'

(function (t) {
  function e(e) {
    for (var a, c, r = e[0], i = e[1], l = e[2], p = 0, u = []; p < r.length; p++) c = r[p], Object.prototype.hasOwnProperty.call(s, c) && s[c] && u.push(s[c][0]), s[c] = 0;
    for (a in i) Object.prototype.hasOwnProperty.call(i, a) && (t[a] = i[a]);
    d && d(e);
    while (u.length) u.shift()();
    return o.push.apply(o, l || []), n()
  }

  function n() {
    for (var t, e = 0; e < o.length; e++) {
      for (var n = o[e], a = !0, r = 1; r < n.length; r++) {
        var i = n[r];
        0 !== s[i] && (a = !1)
      }
      a && (o.splice(e--, 1), t = c(c.s = n[0]))
    }
    return t
  }

  var a = {}, s = {app: 0}, o = [];

  function c(e) {
    if (a[e]) return a[e].exports;
    var n = a[e] = {i: e, l: !1, exports: {}};
    return t[e].call(n.exports, n, n.exports, c), n.l = !0, n.exports
  }

  c.m = t, c.c = a, c.d = function (t, e, n) {
    c.o(t, e) || Object.defineProperty(t, e, {enumerable: !0, get: n})
  }, c.r = function (t) {
    "undefined" !== typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {value: "Module"}), Object.defineProperty(t, "__esModule", {value: !0})
  }, c.t = function (t, e) {
    if (1 & e && (t = c(t)), 8 & e) return t;
    if (4 & e && "object" === typeof t && t && t.__esModule) return t;
    var n = Object.create(null);
    if (c.r(n), Object.defineProperty(n, "default", {
      enumerable: !0,
      value: t
    }), 2 & e && "string" != typeof t) for (var a in t) c.d(n, a, function (e) {
      return t[e]
    }.bind(null, a));
    return n
  }, c.n = function (t) {
    var e = t && t.__esModule ? function () {
      return t["default"]
    } : function () {
      return t
    };
    return c.d(e, "a", e), e
  }, c.o = function (t, e) {
    return Object.prototype.hasOwnProperty.call(t, e)
  }, c.p = "http://localhost:8080/";
  var r = window["webpackJsonp"] = window["webpackJsonp"] || [], i = r.push.bind(r);
  r.push = e, r = r.slice();
  for (var l = 0; l < r.length; l++) e(r[l]);
  var d = i;
  o.push([0, "chunk-vendors"]), n()
})({
  0: function (t, e, n) {
    t.exports = n("56d7")
  }, "56d7": function (t, e, n) {
    "use strict";
    n.r(e);
    n("a589"), n("587a"), n("6b42");
    var a = n("1157"), s = n.n(a);
    (function (t) {
      const e = window.location.pathname, n = e.split("/");
      t(document).ready(function () {
        const a = t("#main_tabnav_content");
        n[1].length > 0 ? document.body.classList.add(n[1]) : document.body.classList.add("home"), t("p:empty", a).remove(), t("a", a).each(function (t, e) {
          "back" === e.innerText && e.classList.add("btn")
        }), e.startsWith("/infos") && t("table", a).addClass("no-flex");
        const s = t("#header .lead");
        e.startsWith("/session/new") && s.text("Find your information in our"), e.startsWith("/infos/help") && s.text("Please feel free to edit your profile as you wish"), e.startsWith("/infos/privacy") && s.text("Please read our search instructions");
        const o = t("form[action='/session']");
        if (o.length > 0) {
          t("input[name=login]").attr("placeholder", "Username"), t("input[name=password]").attr("placeholder", "Password");
          let e = '<div class="page-title">';
          e += '<img src="' + kiosk + '" alt="Icon kiosk">', e += '<p class="login-title">Online Due Diligence check</p>', e += "</div>", a.prepend(e), t("p", o).each(function (t, e) {
            e.innerText.startsWith("Please log in") && e.remove()
          })
        }
      })
    })(s.a);
    const o = ["peps", "sanctions", "watchblacks", "pils", "soes"];
    (function (t) {
      const e = window.location.pathname.split("/");
      let n = "";
      const a = function (t, a) {
        let s = "";
        switch (e[1]) {
          case"peps":
            s = "PEP Desk";
            break;
          case"sanctions":
            s = "Sanction";
            break;
          case"watchblacks":
            s = "W&B";
            break;
          case"pils":
            s = "PIL";
            break;
          case"soes":
            s = "SOEs/Entities";
            break
        }
        let o = '<dl class="search-summery screen-only">';
        return o += "<dt>".concat(s, " Search Result</dt>"), o += "<dd>".concat(t.text().split(", ")[1], "</dd>"), o += "<dt>Search in all name fields</dt>", o += 1 === +n ? "<dd>".concat(n, " match found</dd>") : +n > 100 ? "<dd>> 100 matches found</dd>" : "<dd>".concat(n, " matches found</dd>"), a && (o += "<dt>Details for</dt>", o += "<dd><strong>".concat(a, "</strong></dd>")), o += "</dl>", o
      };
      t(document).ready(function () {
        const s = t("#main_tabnav_content");
        if (t("#main_tabnav ul").addClass("nav"), t("#main_tabnav li a").each(function (e, a) {
          const s = t(a), o = t("ins", s).detach();
          s.html("".concat(s.text(), "<br>").concat(o.text())), s.parent().hasClass("active") && (n = "no hits" === o.text() ? 0 : "> 100 hits" === o.text() ? 101 : o.text().match(/\d+/)[0])
        }), o.includes(e[1]) || "no-hits" === e[1]) {
          const n = t("h3", s), o = n.eq(0), c = o.text().replace("Info4C", "info4c");
          o.text(c), o.text().replace("Info4C", "info4c");
          const r = o.nextUntil("table", ":not(div)"),
            i = t("<div></div>").addClass("print-only").append(o.clone()).append(r.clone());
          s.prepend(i);
          let l = !1;
          o.detach(), e.length > 2 && e[2].length > 0 && (r.find("code"), l = r.find("code").last().text(), t("table:first", s).addClass("detail-table")), s.prepend(a(o, l));
          const d = n.eq(1);
          if (d.length > 0 && d.text().length > 0) {
            const t = d.text().replace("[", "(").replace("]", ")");
            d.addClass("relatives").text(t)
          } else d.remove();
          if (r.length > 0) {
            const e = t("code", r).last();
            e.text().includes("No records found") && e.parent().addClass("no-hits screen-only"), r.each(function (e, n) {
              n.innerText.includes("More than 100 records") && (n.classList.add("too-many-hits"), n.classList.add("screen-only")), 0 === n.classList.length && t(n).remove()
            })
          } else {
            const e = t("code", s);
            e.text().includes("Please enter a search term") && e.parent().addClass("no-term screen-only"), e.text().includes("longer than 1 character") && (e.prev().remove(), e.parent().addClass("one-char-term screen-only"))
          }
        }
        t("tr", s).each(function (t, e) {
          const n = e.getAttribute("bgcolor");
          n && ("#eeeeee" === n ? e.setAttribute("bgcolor", "#ecf1f3") : e.setAttribute("bgcolor", "#ffffff"))
        })
      })
    })(s.a), function (t) {
      const e = window.location.pathname, n = function (t) {
        const n = t.getAttribute("href");
        return "/" === n && o.includes(e.split("/")[1]) ? "active" : n === e ? "active" : ""
      };
      t(document).ready(function () {
        const a = t("#nav"), s = t("code:contains('This is a limited account')"),
          o = t("table", a).detach(), c = t("tr", o), r = t("<ul/>", {class: "main-nav nav"});
        t("td", c.first()).first().find("a").each(function (e, a) {
          const s = t("<li/>", {class: n(a)}).append(a);
          r.append(s)
        }), a.prepend(r);
        const i = t("form", o).wrap('<div class="form"></div>');
        t("input[type=submit]", i).hide(), t("input[type=checkbox]", i).each(function (e, n) {
          t(n).after('<span class="checkmark"></span>')
        });
        const l = '<div class="form-label"><img src="' + magnify + '" alt="search icon"/> Search</div>',
          d = t("<div/>", {class: "form"}).append(l).append(i), p = c.first().find("em"),
          u = t("p", p), f = t("<div/>", {class: "user"});
        if (u.length < 1) {
          const e = p.text().split(":")[1].replace(/ /g, "").split("(")[0];
          f.append("<p>Logged in as:<br>".concat(e, "</p>"));
          let n = "unlimited";
          if (s.length > 0) {
            const t = s.text().match(/\d+/);
            n = t ? t[0] : "no searches left"
          }
          f.append('<p>Searches Left: <span class="searches-left">'.concat(n, "</span></p>")), f.append('<a href="#" onclick="javascript:print(); return false;"><button class="btn">print</button></a>');
          const a = t("a", p).html('<button class="btn">logout</button>');
          f.append(a)
        }
        if (e.startsWith("/infos") || e.startsWith("/ratings")) t("#content").append(f); else {
          const e = t("<div/>", {class: "search-container"});
          e.append(d).append(f), a.append(e)
        }
      })
    }(s.a), String.prototype.startsWith || Object.defineProperty(String.prototype, "startsWith", {
      value: function (t, e) {
        var n = e > 0 ? 0 | e : 0;
        return this.substring(n, n + t.length) === t
      }
    }), String.prototype.includes || (String.prototype.includes = function (t, e) {
      return "number" !== typeof e && (e = 0), !(e + t.length > this.length) && -1 !== this.indexOf(t, e)
    })
  }, a589: function (t, e, n) {
  }
});