// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery'
import 'jquery-ui'
import Rails from 'jquery-ujs';
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../stylesheets/application.css'
import '../javascript/vendors'
import '../javascript/app'
// import '../javascript/calendarview'
import '../javascript/controls'
import '../javascript/dragdrop'
import '../javascript/effects'
import '../javascript/prototype'
import '../javascript/wice_grid'

// Turbolinks.start()
ActiveStorage.start()
